<template>
  <div
    class="camera-area-overlay camera-start-button"
    @click="$emit('startCamera')"
  >
    <v-icon
      size="40"
      class="mb-2"
      color="#374151"
      :icon="mdiCamera"
    />
    <div
      class="status-label"
      :class="{ 'text-error': cameraForbidden }"
    >
      {{ displayText }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { mdiCamera } from '@mdi/js';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

defineEmits<{
  (e: 'startCamera'): void,
  (e: 'back'): void}
>();

const props = defineProps({
  cameraStarted: {
    type: Boolean,
    default: false,
  },
  cameraForbidden: {
    type: Boolean,
    default: false,
  },
});
const displayText = computed(
  () => {
    if (props.cameraForbidden) {
      return t('cameraForbidden');
    }
    if (!props.cameraStarted) {
      return t('startCamera');
    }
    return t('cameraLoading');
  },
);

</script>
<style lang="scss" scoped>
.camera-area-overlay {
  position: absolute;
  top:0;
  width: 90%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.status-label {
  font-size: 1.25rem;
}
</style>

